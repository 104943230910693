import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './StyledIncidentForm.css';

const StyledIncidentForm = ({ onClose, onSubmit, location }) => {
    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [incidentTypes, setIncidentTypes] = useState([]);
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [notes, setNotes] = useState('');
    const [radius, setRadius] = useState(0.5);
    const [incidentCommander, setIncidentCommander] = useState('');
    const [stagingManager, setStagingManager] = useState('');
    const [communications, setCommunications] = useState('');
    
    // State to store the extracted user info
    const [userInfo, setUserInfo] = useState({
        userName: 'Unknown',
        userId: 'Unknown'
    });

    useEffect(() => {
        // Extract user information from the URL
        const params = new URLSearchParams(window.location.search);
        const user = params.get('user');
        console.log('Raw user data from URL:', user);
        if (user) {
            try {
                const decodedUser = JSON.parse(decodeURIComponent(user));
                console.log('Decoded user data:', decodedUser); 
                setUserInfo({
                    userName: decodedUser.userName || 'Unknown',
                    userId: decodedUser.userId || 'Unknown'
                });
            } catch (error) {
                console.error('Failed to parse user data from URL:', error);
            }
        }

        const API_URL = process.env.REACT_APP_API_URL || 'https://merlin.westchesterrtc.com';
        
        const fetchIncidentTypes = async () => {
            try {
                const response = await fetch(`${API_URL}/api/admin/database/incidentTypes`, {
                    credentials: 'include',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setIncidentTypes(data);
                console.log('Fetched incident types:', data);
            } catch (error) {
                console.error('Failed to fetch incident types:', error);
            }
        };

        fetchIncidentTypes();

        const now = new Date();
        setDate(now.toISOString().split('T')[0]);
        setTime(now.toTimeString().split(' ')[0].slice(0, 8));
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!name) {
            alert('Please fill out the name field before submitting.');
            return;
        }
        const submissionData = {
            name,
            type,
            location_lat: location ? location.lat : null,
            location_long: location ? location.lng : null,
            notes,
            radius,
            date,
            time,
            incident_commander: incidentCommander,
            staging_manager: stagingManager,
            communications,
            created_by_name: userInfo.userName,
            created_by_userid: userInfo.userId
        };
        console.log('Submitting incident with:', submissionData);  // Log here to ensure userInfo is captured
        onSubmit(submissionData);
        setName('');
        setType('');
        setNotes('');
        setRadius(0.5);
        setDate('');
        setTime('');
        setIncidentCommander('');
        setStagingManager('');
        setCommunications('');
    };

    return (
        <Modal 
            show={true} 
            onHide={onClose} 
            centered
            className="incident-form-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>New Incident</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formType" className="mt-3">
                        <Form.Label>Type</Form.Label>
                        <Form.Control
                            as="select"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                            required
                        >
                            <option value="">Select Type</option>
                            {incidentTypes.map((incidentType) => (
                                <option key={incidentType.id} value={incidentType.name}>
                                    {incidentType.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formLocation" className="mt-3">
                        <Form.Label>Location (Click on Map)</Form.Label>
                        <Form.Control
                            type="text"
                            value={location ? 
                                `${location.lat.toFixed(6)}, ${location.lng.toFixed(6)}` : 
                                "Click location on map to set coordinates..."
                            }
                            readOnly
                            style={{
                                fontStyle: location ? 'normal' : 'italic',
                                color: location ? '#fff' : '#888'
                            }}
                        />
                    </Form.Group>
                    <Form.Group controlId="formIncidentCommander" className="mt-3">
                        <Form.Label>Incident Commander</Form.Label>
                        <Form.Control
                            type="text"
                            value={incidentCommander}
                            onChange={(e) => setIncidentCommander(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formStagingManager" className="mt-3">
                        <Form.Label>Staging Manager</Form.Label>
                        <Form.Control
                            type="text"
                            value={stagingManager}
                            onChange={(e) => setStagingManager(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formCommunications" className="mt-3">
                        <Form.Label>Communications</Form.Label>
                        <Form.Control
                            type="text"
                            value={communications}
                            onChange={(e) => setCommunications(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formNotes" className="mt-3">
                        <Form.Label>Notes</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formRadius" className="mt-3">
                        <Form.Label>Radius (miles)</Form.Label>
                        <Form.Control
                            type="number"
                            value={radius}
                            onChange={(e) => setRadius(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formDate" className="mt-3" style={{ display: 'none' }}>
                        <Form.Label>Date</Form.Label>
                        <Form.Control
                            type="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formTime" className="mt-3" style={{ display: 'none' }}>
                        <Form.Label>Time</Form.Label>
                        <Form.Control
                            type="time"
                            value={time}
                            onChange={(e) => setTime(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" size="lg" onClick={handleSubmit}> 
                    Submit
                </Button>    
            </Modal.Footer>
        </Modal>
    );
};

export default StyledIncidentForm;
